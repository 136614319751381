import { Utils } from "@/scripts/Utils";

/**
 * @desc format de données utilisé pour contenir toutes les visites d'un type d'utilisateur classées par année
 */
export class VisitDataByYear {

  /**
   * @desc ajoute une visite aux données à la date indiquée
   * @param date date de la visite
   */
  public addVisit(date : Date) : void {
    const year = Utils.getFirsYearOfSchoolYearFromDate(date);
    let yearData = this.years.find(x => x.year === year);
    if(!yearData) {
      this.years.push({year, data: []});
      yearData = this.years[this.years.length-1];
    }

    const dateTime = date.getTime();
    const index = yearData.data.findIndex(x => x[0] === dateTime);
    if(index > -1) {
      ++yearData.data[index][1];
    }
    else {
      yearData.data.push([dateTime, 1]);
    }
  }

  /**
   * @desc cherche et renvoie le tableau de données correspondant à l'année indiquée
   * @param year le numéro du début de l'année scolaire
   */
  public getVisitDataFromYear(year : number) : Array<Array<number>> {
    const yearData = this.years.find(x => x.year === year);
    if(yearData) return yearData.data;
    return [];
  }

  // année de commencement de l'année scolaire
  years : Array<{year: number, data: Array<Array<number>>}> = [];
}