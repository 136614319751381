
/**
 * @desc format de données reçu correspondant à une visite
 */
export class Visit {
  constructor(data ? : any) {
    this.id = data.id;

    this.date = new Date(data.date);
    this.userId = data.userId;
    this.userStatus = data.userStatus;
  }

  id = 0;
  date: Date;
  userId : number;
  userStatus : number;
}