
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import {Visit} from '@/interfaces/Visit';
  import {VisitDataByYear} from '@/interfaces/VisitDataByYear';
  import 'zingchart/es6';
  import zingchartVue from 'zingchart-vue';
  import { Utils } from '@/scripts/Utils';

  /**
   * @desc page des statistiques des visites sur le site
   */
  @Component({
    components: {
      'zingchart' : zingchartVue
    },
  })
  export default class Statistics extends Vue {
    private allVisits : Visit[] = [];
    private chartMinValue = new Date().getTime();
    private chartMaxValue = new Date().getTime() + 86400 * 1000 * 365;

    private parentValues= new VisitDataByYear();
    private classroomValues= new VisitDataByYear();
    private teacherValues= new VisitDataByYear();

    private years: Array<{text: string, key: number}> = [];
    private selectedYear = Utils.getFirsYearOfSchoolYearFromDate(new Date());

    private myConfig = {};

    mounted() : void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_teacher
        && AuthenticationService.getUserStatus() !== Consts.user_status_admin){
        this.$router.push('/');
        return;
      }


      this.loadVisits();
      this.initYearFilters();
      this.selectedYearChanged();

      this.myConfig = this.getMyConfigData();
    }

    /**
     * @desc charge toutes les visites enregistrées
     */
    private loadVisits() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(  UrlConsts.getAllVisits, headers)
        .then((response) => {
          this.allVisits = response.data.map((x : any) => new Visit(x));
          this.computeVisits();
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc transforme les données envoyées par le serveur, pour pouvoir être affichées en graphique
     */
    private computeVisits() : void {
      for(const visit of this.allVisits) {
        const visitDate = visit.date;
        visitDate.setDate(1);
        visitDate.setHours(1);
        visitDate.setMinutes(0);
        visitDate.setSeconds(0);
        visitDate.setMilliseconds(0);

        switch(visit.userStatus) {
          case Consts.user_status_classroom: 
            this.classroomValues.addVisit(visitDate);
            break;

          case Consts.user_status_parents: 
            this.parentValues.addVisit(visitDate);
            break;

          case Consts.user_status_teacher: 
            this.teacherValues.addVisit(visitDate);
            break;
        }
      }

      this.myConfig = this.getMyConfigData();
    }

    /**
     * @desc initialise les filtres selectionnables par année
     */
    private initYearFilters() : void {
      const beginYear = Consts.startYear;
      const now = new Date();
      let endYear = now.getFullYear();
      if(now.getMonth()+1 >= 8) ++endYear;

      for(let i = beginYear; i < endYear; ++i) {
        this.years.push(
          {text: i + '-' + (i+1), key: i}
        );
      }
    }

    /**
     * @desc met à jour l'affichage du graphique 
     */
    private selectedYearChanged() : void {
      const startDate = new Date();
      startDate.setFullYear(this.selectedYear);
      startDate.setMonth(7);
      startDate.setDate(31);
      this.chartMinValue = startDate.getTime();

      startDate.setFullYear(this.selectedYear +1);
      startDate.setMonth(7);
      startDate.setDate(30);
      this.chartMaxValue = startDate.getTime();

      this.myConfig = this.getMyConfigData();
    }

    /**
     * @desc renvoie un nouvel objet contenant les configurations pour le graphique
     */
    private getMyConfigData() : any {
      return  {
          type: 'bar',
          utc: true,
          //'adjust-layout': true,

          'scale-x': {
            'min-value': this.chartMinValue,
            'max-value': this.chartMaxValue,
            step: "month",
            labels: [ "Sep", "Oct", "Nov", "Dec", "Jan", "Fev", "Mars", "Avril", 'Mai', 'Juin', 'Juil', 'Août', 'Sep' ],
            transform: {
              type: "date",
              all: "%M"
            }
          },
          legend: {
            toggleAction: 'remove'
          },
          title: {text: 'Statistiques de connexion'},
          series: [{
              values: this.parentValues.getVisitDataFromYear(this.selectedYear),
              text: 'Parents',
            },
            {
              values: this.classroomValues.getVisitDataFromYear(this.selectedYear),
              text: 'Classes',
            },
            {
              values: this.teacherValues.getVisitDataFromYear(this.selectedYear),
              text: 'Enseignants',
            }
          ],
        };
    }
  }
